import { ApiError, AppError, Language, StringSignature } from '@/types/types';
import { Locale, formatDistance } from 'date-fns';
import { flatten } from 'lodash';
import { isAxiosError } from 'axios';
import { DateValue } from '@mantine/dates';
import { Attribute } from '@/api/AttributeApi';
import { ErrorCode } from '@/configs/errorCode';
import { ComponentCategory } from '@/api/ComponentCategoryApi';
import {
	CreatorSelectedAttributes,
	CreatorSelectedComponents,
} from '@/types/creator';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';

export function formatDateForInput(date: moment.MomentInput, format?: string) {
	return moment(date, format).format('YYYY-MM-DD');
}

export function formatDateForPresentation(date: moment.MomentInput) {
	if (!date) return '-';
	return moment(date).format('DD.MM.YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('HH:mm');
}

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

const PHONE_REGEX =
	/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

/**
 *
 * @param phoneNumber validate international
 * @returns
 */
export function validatePhoneNumber(phoneNumber: string) {
	return PHONE_REGEX.test(phoneNumber) && phoneNumber.length >= 9;
}

/**
 *
 * @param accountNumber validate polish
 * @returns
 */
export function validateAccountNumber(accountNumber: string) {
	const trimmed = accountNumber.trim();
	return /\d{26}/.test(trimmed);
}

/**
 *
 * @param postalCode validate polish
 * @returns
 */
export function validatePostalCode(postalCode: string) {
	const trimmed = postalCode.trim();
	return /^[0-9]{2}-[0-9]{3}/.test(trimmed);
}

export function isValidDateObj(date: moment.MomentInput) {
	return moment(date).isValid();
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function randomDate(start: Date, end: Date) {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
}

export function sortByDate(
	dateA: string | Date,
	dateB: string | Date,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function stripHTML(string: string) {
	return string.replace(/(<([^>]+)>)/gi, '');
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function getContent(conent: StringSignature, slug: string) {
	return conent[slug];
}

export function formatDateFromNow(date: Date, locale: Locale) {
	return formatDistance(date, new Date(), {
		locale,
		includeSeconds: true,
		addSuffix: true,
	});
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isApiError = (error: unknown): error is ApiError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as ApiError).message
	);
};

export const isAppError = (error: unknown): error is AppError => {
	return !!(error as AppError).status && !!(error as AppError).stack;
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export function getImageSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatDateForApi(date: DateValue) {
	if (!date) return null;

	return moment(date).format('YYYY-MM-DD');
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function natSort(nameA = '', nameB = '', order: 'asc' | 'desc' = 'asc') {
	let a: string | number = Number(nameA),
		b: string | number = Number(nameB);

	if (isNaN(a) || isNaN(b)) {
		return order === 'asc'
			? nameA.localeCompare(nameB)
			: nameB.localeCompare(nameA);
	}

	return order === 'asc' ? a - b : b - a;
}

export function inputWidthValue(value: string) {
	return `${value.length + 3}ch`;
}

export function getAttributeName(attribute: Attribute) {
	return attribute.suffix
		? `${attribute.name} ${attribute.suffix}`
		: attribute.name;
}

export function getInitialSelectedComponents(
	componentCategories: ComponentCategory[]
) {
	const selectedComponents: CreatorSelectedComponents = {};

	for (const componentCategory of componentCategories) {
		selectedComponents[componentCategory.id] = null;
	}

	return selectedComponents;
}

export function getInitialSelectedAttributes(
	componentCategories: ComponentCategory[]
) {
	const selectedAttributes: CreatorSelectedAttributes = {};

	for (const componentCategory of componentCategories) {
		selectedAttributes[componentCategory.id] = {};

		for (const attribute of componentCategory.attributes) {
			if (!attribute.attributeValues.length) continue;

			selectedAttributes[componentCategory.id][attribute.id] =
				(componentCategory.chosenAttributeValues &&
					componentCategory.chosenAttributeValues[attribute.id]) ||
				'';
		}
	}

	return selectedAttributes;
}

export function getChromeVersion() {
	const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
	return raw ? parseInt(raw[2], 10) : false;
}

export function getSupportedSizeUnit(type: 'width' | 'height') {
	const version = getChromeVersion();

	if (!version || version >= APP_CONFIG.CSS_DVH_CHROME_SUPPORTED)
		return type === 'width' ? 'dvw' : 'dvh';

	return type === 'width' ? 'vw' : 'vh';
}
