export const QueryKey = {
	content: 'content',
	products: 'products',
	productTypes: 'product-types',
	componentCategories: 'component-categories',
	productComponents: 'product-components',
	components: 'components',
	attributes: 'attributes',
	attributeValues: 'attribute-values',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
