import React, { useContext, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@mantine/hooks';
import {
	ApiErrorMessage,
	ForeignLanguage,
	Language,
	StringSignature,
} from '@/types/types';
import { Loading } from '@/components/ui/loading/Loading';
import { isEmpty } from 'lodash';
import { isAppError, isDefaultLanguage } from '@/utils/utilities';
import { ErrorCode } from '@/configs/errorCode';
import parse, { Element, domToReact } from 'html-react-parser';
import ContentApi, { Content, ContentType } from '@/api/ContentApi';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/de';

const errorSlug = 'error.';

export type AppContents = StringSignature<Content>;

interface Context {
	getContent: (
		slug: string,
		options?: {
			links?: StringSignature<React.RefAttributes<HTMLAnchorElement>>;
			vars?: StringSignature<string | number>;
		}
	) => string;
	language: Language;
	setLanguage: (lang: Language) => void;
	getErrorMessage: (codes: ApiErrorMessage) => string;
	getMutationErrorMessage: (error: unknown) => string;
	getEntityText: <
		TObj extends {
			translations: Record<
				ForeignLanguage,
				Partial<Record<keyof TObj, string>>
			> | null;
		}
	>(
		obj: TObj,
		key: keyof TObj
	) => string;
}

const ContentContext = React.createContext<Context>(null!);

export const useContent = () => useContext(ContentContext);

export const ContentProvider = ({ children }: { children: ReactNode }) => {
	const [content, setContent] = useLocalStorage<AppContents>({
		key: 'content',
		defaultValue: {},
		getInitialValueInEffect: false,
	});

	const [language, setLanguage] = useLocalStorage<Language>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});

	const isDefault = language === APP_CONFIG.DEFAULT_LANGUAGE;

	const [_, setRefresher] = useState(false);
	const refreshApp = () => setRefresher((prev) => !prev);

	useEffect(() => {
		moment.locale(language === 'GB' ? 'en-gb' : language.toLowerCase());
		refreshApp();
	}, [language]);

	const checkIfExist = (slug: string) => {
		if (!content[slug]) return false;

		if (isDefault)
			return content[slug].type === ContentType.IMAGE
				? !!content[slug].image
				: !!content[slug].content;

		if (!content[slug].translations || !content[slug].translations[language])
			return false;

		if (content[slug].type === ContentType.IMAGE)
			return !!content[slug].translations[language].image;

		return !!content[slug].translations[language].content;
	};

	const contentQuery = useQuery({
		queryKey: [ContentApi.queryKey],
		queryFn: ContentApi.getAll,
		staleTime: 1000 * 60 * 5,
		select: (contents) =>
			contents.reduce<AppContents>((acc, curr) => {
				acc[curr.slug] = curr;
				return acc;
			}, {}),
	});

	useEffect(() => {
		if (!contentQuery.data) return;

		setContent(contentQuery.data);
	}, [contentQuery.data]);

	const getText = (
		slug: string,
		vars: StringSignature<string | number> = {}
	) => {
		let text = isDefault
			? content[slug].content
			: content[slug].translations[language].content;

		for (const [variable, value] of Object.entries(vars)) {
			text = text.replaceAll(variable, value.toString());
		}

		return text;
	};

	const getElement = (
		slug: string,
		options?: {
			links?: StringSignature<React.RefAttributes<HTMLAnchorElement>>;
			vars?: StringSignature<string | number>;
		}
	) => {
		const raw = getText(slug);

		return parse(raw, {
			replace: (domNode) => {
				const node = domNode as Element;

				if (
					node.name === 'a' &&
					options?.links &&
					options?.links[node.attribs.href]
				) {
					return (
						<a {...options.links[node.attribs.href]}>
							{domToReact(node.children as Element[])}
						</a>
					);
				}
			},
		});
	};

	const getImage = (slug: string) => {
		return `${import.meta.env.VITE_API_URL_UPLOADS}${
			isDefault
				? content[slug].image.path
				: content[slug].translations[language].image.path
		}`;
	};

	const getContent: Context['getContent'] = (slug, options) => {
		if (!checkIfExist(slug)) return slug;

		switch (content[slug].type) {
			case ContentType.TEXT:
				return getText(slug, options?.vars);
			case ContentType.ELEMENT:
				return getElement(slug, options) as string;
			case ContentType.IMAGE:
				return getImage(slug);
			default:
				return slug;
		}
	};

	const getErrorMessage: Context['getErrorMessage'] = (codes) => {
		const codeToMessage = (code: ErrorCode | string) =>
			Object.values(ErrorCode).includes(code as ErrorCode)
				? getText(`${errorSlug}${code}`)
				: '';

		const errorMessages = Array.isArray(codes)
			? codes.map((code) => codeToMessage(code))
			: [codeToMessage(codes)];

		const message =
			errorMessages.filter((m) => !!m).join('. ') ||
			codeToMessage(ErrorCode.GENERIC);

		return message;
	};

	const getMutationErrorMessage: Context['getMutationErrorMessage'] = (
		error
	) => {
		if (isAppError(error)) getErrorMessage(error.stack);
		return getErrorMessage(ErrorCode.GENERIC);
	};

	const getEntityText: Context['getEntityText'] = (obj, key) => {
		if (isDefaultLanguage(language)) return obj[key] as string;

		return !!obj.translations &&
			!!obj.translations[language] &&
			!!obj.translations[language][key]
			? (obj.translations[language][key] as string)
			: (obj[key] as string);
	};

	return (
		<ContentContext.Provider
			value={{
				getContent,
				language,
				setLanguage,
				getErrorMessage,
				getMutationErrorMessage,
				getEntityText,
			}}
		>
			{isEmpty(content) ? <Loading /> : children}
		</ContentContext.Provider>
	);
};
