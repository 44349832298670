import { ErrorCode } from '@/configs/errorCode';
import { ApiError } from '@/types/types';
import axios, { AxiosError, HttpStatusCode, isAxiosError } from 'axios';

const httpClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
	},
});

httpClient.interceptors.request.use((request) => {
	if (request.data instanceof FormData) request.headers.setContentType('');

	return request;
});

httpClient.interceptors.response.use(
	(response) => response,
	(error) => {
		if (isAxiosError<ApiError>(error)) {
			if (error.code === AxiosError.ECONNABORTED) throw error;

			const stack = Array.isArray(error.response!.data.message)
				? error.response!.data.message
				: [error.response!.data.message];

			throw {
				status: error.response!.data.status,
				stack,
			};
		}

		throw {
			code: HttpStatusCode.InternalServerError,
			stack: [ErrorCode.GENERIC],
		};
	}
);

export default httpClient;
