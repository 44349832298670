import { ReactNode } from 'react';
import {
	Anchor,
	Badge,
	Image,
	Burger,
	Button,
	Center,
	Divider,
	Group,
	MantineProvider as Mantine,
	MantineTheme,
	MantineThemeOverride,
	Menu,
	Modal,
	NativeSelect,
	Pagination,
	Radio,
	RadioGroup,
	ScrollArea,
	ScrollAreaAutosize,
	Select,
	Tooltip,
	Notification,
	rem,
	Stack,
	Checkbox,
	Popover,
	Accordion,
	BackgroundImage,
	Title,
	Drawer,
	Highlight,
	RangeSlider,
	Slider,
	Progress,
	Overlay,
	LoadingOverlay,
	PasswordInput,
	Textarea,
	TextInput,
	Loader as MantineLoader,
	CSSVariablesResolver,
} from '@mantine/core';
import { getSupportedSizeUnit } from '@/utils/utilities';
import { DatePicker } from '@mantine/dates';
import { Loader } from '@/components/ui/loading/partials/Loader';
import ArrowDownIcon from '@/assets/icons/arrowDown.svg?react';
import EyeIcon from '@/assets/icons/eye.svg?react';
import CloseIcon from '@/assets/icons/close.svg?react';
import InfoIcon from '@/assets/icons/info.svg?react';
import CheckIcon from '@/assets/icons/check.svg?react';
import APP_CONFIG from '@/configs/appConfig';
import classNames from 'classnames';
import styles from '../sass/mantine.module.scss';
import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.layer.css';

const colors: MantineThemeOverride['colors'] = {
	gray: [
		'#fefdfd',
		'#fdfdfc',
		'#fbfafa',
		'#f1efee',
		'#d9d7d6',
		'#c1bfbe',
		'#b9b1a8',
		'#918f8f',
		'#6c6c6b',
		'#545453',
	],
	dark: [
		'#fcfcfd',
		'#f8fafc',
		'#eef2f6',
		'#e3e8ef',
		'#cdd5df',
		'#9aa4b2',
		'#697586',
		'#4b5565',
		'#364152',
		'#202939',
	],
	green: [
		'#e6f6f4',
		'#d9f2ef',
		'#b0e4dd',
		'#00a991',
		'#009883',
		'#008774',
		'#007f6d',
		'#006557',
		'#004c41',
		'#07353f',
	],
};

const components: MantineThemeOverride['components'] = {
	Pagination: Pagination.extend({
		defaultProps: {
			withControls: true,
			gap: 'sm',
			radius: 0,
		},
		classNames: {
			control: styles.paginationControl,
			dots: styles.paginationDots,
		},
	}),
	Burger: Burger.extend({
		defaultProps: {
			color: 'green.9',
			size: 30,
		},
	}),
	Badge: Badge.extend({
		defaultProps: {
			radius: 0,
			h: 46,
			p: 0,
			lh: 'md',
			fz: 'md',
			fw: 500,
		},
		classNames: {
			label: styles.badgeLabel,
		},
	}),
	Modal: Modal.extend({
		defaultProps: {
			centered: true,
			withCloseButton: true,
			radius: 4,
			zIndex: APP_CONFIG.Z_INDEX.MODAL,
			closeButtonProps: { size: 24 },
			size: `min(95${getSupportedSizeUnit('width')}, ${rem(528)})`,
		},
		classNames: {
			root: styles.modalRoot,
			inner: styles.modalInner,
			content: styles.modalContent,
			header: styles.modalHeader,
			body: styles.modalBody,
			close: styles.modalClose,
			title: styles.modalTitle,
		},
	}),
	Button: Button.extend({
		defaultProps: {
			radius: 0,
		},
	}),
	Menu: Menu.extend({
		defaultProps: {
			position: 'bottom',
			shadow: 'sm',
			radius: 0,
			transitionProps: {
				transition: 'scale',
			},
		},
		classNames: {
			dropdown: styles.menuDropdown,
			item: styles.menuItem,
			divider: styles.menuDivider,
			itemLabel: styles.menuItemLabel,
			itemSection: styles.menuItemSection,
		},
	}),
	Divider: Divider.extend({
		defaultProps: {
			color: 'dark.4',
			size: 1,
		},
		classNames: {
			label: styles.dividerLabel,
		},
	}),
	Select: Select.extend({
		defaultProps: {
			rightSection: <ArrowDownIcon />,
			radius: 4,
			size: 'lg',
			withCheckIcon: false,
			allowDeselect: false,
			comboboxProps: {
				transitionProps: {
					duration: 100,
					transition: 'scale-y',
					timingFunction: 'ease',
				},
				zIndex: APP_CONFIG.Z_INDEX.SELECT,
			},
		},
		classNames: {
			input: styles.selectInput,
			section: styles.selectSection,
			option: styles.selectOption,
			dropdown: styles.selectDropdown,
			empty: styles.selectEmpty,
		},
	}),
	Image: Image.extend({
		defaultProps: {
			radius: 0,
			fit: 'contain',
		},
		classNames: {
			root: 'fade-in',
		},
	}),
	NativeSelect: NativeSelect.extend({
		defaultProps: {
			rightSection: <ArrowDownIcon />,
			radius: 4,
			size: 'lg',
		},
		classNames: {
			input: styles.selectInput,
			section: styles.selectRightSection,
		},
	}),
	ScrollArea: ScrollArea.extend({
		defaultProps: {
			scrollbarSize: 7,
			type: 'auto',
			['data-scrollarea']: 'true',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	}),
	ScrollAreaAutosize: ScrollAreaAutosize.extend({
		defaultProps: {
			scrollbarSize: 7,
			type: 'auto',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	}),
	Tooltip: Tooltip.extend({
		defaultProps: {
			position: 'top',
			transitionProps: {
				transition: 'pop',
				duration: 200,
			},
			multiline: true,
			withArrow: true,
			arrowSize: 8,
			events: { hover: true, focus: true, touch: true },
			color: 'black',
			radius: 0,
			zIndex: APP_CONFIG.Z_INDEX.TOOLTIP,
		},
		classNames: {
			tooltip: styles.tooltipTooltip,
		},
	}),
	Group: Group.extend({
		classNames: {
			root: styles.groupRoot,
		},
	}),
	RadioGroup: RadioGroup.extend({}),
	Radio: Radio.extend({
		defaultProps: {
			size: 'xs',
			color: '#f9871b',
		},
		classNames: {
			root: styles.radioRoot,
			body: styles.radioBody,
			labelWrapper: styles.radioLabelWrapper,
			label: styles.radioLabel,
		},
	}),
	Anchor: Anchor.extend({
		defaultProps: {
			underline: 'never',
		},
	}),
	Stack: Stack.extend({
		classNames: {
			root: styles.stackRoot,
		},
	}),
	Checkbox: Checkbox.extend({
		classNames: {
			inner: styles.checkboxInner,
			label: styles.checkboxLabel,
		},
		defaultProps: {
			size: 'lg',
			color: 'dark',
			radius: 4,
		},
	}),
	TextInput: TextInput.extend({
		defaultProps: {
			radius: 4,
			size: 'lg',
		},
		classNames: {
			input: styles.textInputInput,
			label: styles.textInputLabel,
			error: styles.textInputError,
		},
	}),
	Textarea: Textarea.extend({
		defaultProps: {
			radius: 4,
			autosize: true,
			minRows: 4,
			maxRows: 8,
		},
		classNames: {
			input: styles.textareaInput,
		},
	}),
	PasswordInput: PasswordInput.extend({
		defaultProps: {
			radius: 0,
			visibilityToggleIcon: ({ reveal }) => (
				<Center
					className={classNames({
						[styles.passwordInputIconRevealed]: reveal,
					})}
				>
					<EyeIcon />
				</Center>
			),
		},
		classNames: {
			wrapper: styles.passwordInputWrapper,
			input: styles.passwordInputInput,
			section: styles.passwordInputSection,
			error: styles.passwordInputError,
		},
	}),
	Loader: MantineLoader.extend({
		defaultProps: {
			loaders: { ...MantineLoader.defaultLoaders, spinner: Loader },
			type: 'spinner',
		},
	}),
	LoadingOverlay: LoadingOverlay.extend({
		defaultProps: {
			loaderProps: {
				type: 'spinner',
			},
			overlayProps: {
				opacity: 0.5,
			},
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
		},
	}),
	Overlay: Overlay.extend({
		defaultProps: {
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
			backgroundOpacity: 0.25,
		},
	}),
	Progress: Progress.extend({
		defaultProps: {
			size: 'md',
			color: 'green.6',
			radius: 'md',
		},
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.green[0],
			},
		}),
	}),
	Slider: Slider.extend({
		defaultProps: {
			labelTransitionProps: {
				transition: 'fade',
				duration: 250,
			},
			thumbSize: 22,
			size: 6,
		},
		classNames: {
			root: styles.sliderRoot,
			bar: styles.sliderBar,
			thumb: styles.sliderThumb,
			track: styles.sliderTrack,
			label: styles.sliderLabel,
			markLabel: styles.sliderMarkLabel,
		},
	}),
	RangeSlider: RangeSlider.extend({
		defaultProps: {
			color: 'dark.5',
			labelTransitionProps: {
				transition: 'fade',
				duration: 250,
			},
			minRange: 1,
		},
	}),
	Highlight: Highlight.extend({
		defaultProps: {
			color: 'dark',
			highlightStyles: {
				fontWeight: 700,
			},
		},
	}),
	DatePicker: DatePicker.extend({
		classNames: {
			day: styles.datePickerDay,
		},
	}),
	Drawer: Drawer.extend({
		defaultProps: {
			position: 'right',
			zIndex: APP_CONFIG.Z_INDEX.DRAWER,
			size: 592,
			closeButtonProps: {
				iconSize: 20,
			},
		},
		classNames: {
			overlay: styles.drawerInner,
			inner: styles.drawerInner,
			body: styles.drawerBody,
			header: styles.drawerHeader,
			title: styles.drawerTitle,
			close: styles.drawerClose,
		},
	}),
	Title: Title.extend({
		defaultProps: {
			lts: -0.25,
		},
	}),
	Notification: Notification.extend({
		defaultProps: { radius: 0, withCloseButton: false },
		styles: {
			root: {
				zIndex: APP_CONFIG.Z_INDEX.NOTIFICATION,
			},
		},
		classNames: {
			root: styles.notificationRoot,
			icon: styles.notificationIcon,
			closeButton: styles.notificationCloseButton,
		},
	}),
	BackgroundImage: BackgroundImage.extend({
		defaultProps: {},
	}),
	Accordion: Accordion.extend({
		defaultProps: {
			chevronPosition: 'left',
			chevronSize: 24,
			chevron: (
				<ArrowDownIcon
					className="icon-gray400"
					width="1.5rem"
					height="1.5rem"
				/>
			),
			transitionDuration: 250,
		},
		classNames: {
			content: styles.accordionContent,
			item: styles.accordionItem,
			control: styles.accordionControl,
			label: styles.accordionLabel,
			chevron: styles.accordionChevron,
		},
	}),
	Popover: Popover.extend({
		defaultProps: {
			shadow: 'md',
			radius: 0,
			withArrow: false,
		},
		classNames: {
			dropdown: styles.popoverDropdown,
		},
	}),
};

const fontSizes: MantineTheme['fontSizes'] = {
	xs: rem(11),
	sm: rem(12),
	md: rem(14),
	lg: rem(16),
	xl: rem(18),
};

const lineHeights: MantineTheme['lineHeights'] = {
	xs: rem(20),
	sm: rem(20),
	md: rem(22),
	lg: rem(24),
	xl: rem(30),
};

const headings: MantineThemeOverride['headings'] = {
	fontFamily: 'Inter, sans-serif',
	fontWeight: '600',
	sizes: {
		h1: {
			fontSize: rem(40),
			lineHeight: rem(54),
		},
		h2: {
			fontSize: rem(24),
			lineHeight: rem(32),
		},
		h3: {
			fontSize: rem(16),
			lineHeight: rem(22),
		},
	},
};

const spacing: MantineThemeOverride['spacing'] = {
	xs: rem(3),
	sm: rem(8),
	md: rem(16),
	lg: rem(24),
	xl: rem(32),
};

const other: MantineThemeOverride['other'] = {
	fontWeights: {
		normal: 500,
		bold: 600,
	},
	textDimmed: colors.dark![6],
	borderColor: colors.dark![4],
	greySmallBadgeProps: {
		radius: 80,
		h: 32,
		color: 'gray.3',
	},
	notificationProps: {
		info: {
			classNames: {
				root: styles.notificationInfo,
				icon: styles.notificationInfoIcon,
			},
			icon: <InfoIcon />,
		},
		danger: {
			classNames: {
				root: styles.notificationDanger,
				icon: styles.notificationDangerIcon,
			},
			icon: <CloseIcon />,
		},
		warning: {},
		success: {
			classNames: {
				root: styles.notificationSuccess,
				icon: styles.notificationSuccessIcon,
			},
			icon: <CheckIcon />,
		},
	},
};

const breakpoints = Object.entries(APP_CONFIG.BREAKPOINTS).reduce<
	MantineThemeOverride['breakpoints']
>((acc, curr) => {
	acc![curr[0].toLowerCase()] = curr[1];
	return acc;
}, {});

const resolver: CSSVariablesResolver = (theme) => ({
	variables: {
		'--cb-icon-size': '200%',
	},
	light: {},
	dark: {},
});

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	return (
		<Mantine
			theme={{
				fontFamily: 'Inter, sans-serif',
				white: '#ffffff',
				black: '#121926',
				spacing,
				other,
				cursorType: 'pointer',
				colors,
				components,
				headings,
				breakpoints,
				primaryColor: 'green',
				primaryShade: 9,
				fontSizes,
				lineHeights,
			}}
			cssVariablesResolver={resolver}
		>
			{children}
		</Mantine>
	);
};
