import { QueryKey } from '@/configs/queryKeys';
import { Attachment, UUID, ForeignLanguage } from '@/types/types';
import EntityApi from './EntityApi';

export enum ContentType {
	TEXT,
	IMAGE,
	ELEMENT,
}

export interface Content {
	id: UUID;
	slug: string;
	type: ContentType;
	content: string;
	image: Pick<Attachment, 'id' | 'path'>;
	translations: {
		[key in ForeignLanguage]: {
			content: string;
			image: Pick<Attachment, 'id' | 'path'>;
		};
	};
}
class ContentApi extends EntityApi<Content> {
	constructor() {
		super(QueryKey.content);
	}
}

export default new ContentApi();
