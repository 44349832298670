export enum ErrorCode {
	GENERIC = 'genericError',
	USER_NOT_FOUND = 'userNotFound',
	USER_NOT_ACTIVE = 'userNotActive',
	USER_ALREADY_EXISTS = 'userAlreadyExists',
	DUPLICATED_EMAIL = 'duplicatedEmail',
	MISSING_FIELD = 'missingField',
	INVALID_EMAIL = 'emailIsNotValid',
	INVALID_PASSWORD = 'invalidPassword',
	INVALID_PHONE_NUMBER = 'phoneNumberIsNotValid',
	PASSWORD_TOO_WEAK = 'passwordTooWeak',
	DIFFERENT_PASSWORDS = 'differentPasswords',
	DIFFERENT_EMAILS = 'differentEmails',
	INVALID_REFRESH_TOKEN = 'invalidRefreshToken',
	REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
	REFRESH_TOKEN_MALFORMED = 'refreshTokenMalformed',
	INVALID_TOKEN = 'invalidToken',
	INVALID_HASH = 'invalidHash',
	INVALID_POSTAL_CODE = 'invalidPostalCode',
	TOO_MANY_REQUESTS = 'tooManyRequests',
}
