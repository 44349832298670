import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';
import {
	CreatorOptions,
	CreatorProduct,
	CreatorSelectedAttributes,
	SelectedAttributeValue,
} from '@/types/creator';
import { ProductStatus } from '@/api/ProductApi';
import { Component } from '@/api/ComponentApi';
import { UUID } from '@/types/types';
import {
	getInitialSelectedComponents,
	getInitialSelectedAttributes,
} from '@/utils/utilities';
import APP_CONFIG from '@/configs/appConfig';

export interface CreatorSliceState {
	product: CreatorProduct;
	options: CreatorOptions;
}

const initialState: CreatorSliceState = {
	product: {
		id: crypto.randomUUID(),
		netPrice: 0,
		grossPrice: 0,
		productType: null,
		status: ProductStatus.NOT_ADDED,
		updatedAt: '',
		createdAt: '',
		productComponents: [],
		options: '',
	},
	options: {
		isInitialized: false,
		// isCreatorOpened: true,
		// productTypeId: '81596f9a-c59f-4709-99c9-9d2a6e3444f9',
		isCreatorOpened: false,
		productTypeId: undefined,
		selectedComponents: {},
		selectedAttributes: {},
		selectedAttributesHistory: [],
		mobilePreviewExpanded: true,
	},
};

export const creatorSlice = createSlice({
	name: StoreKey.CREATOR,
	initialState,
	reducers: {
		updateCreator: (
			state,
			{ payload }: PayloadAction<Partial<CreatorSliceState>>
		) => {
			Object.assign(state, payload);
		},
		updateProduct: (
			state,
			{ payload }: PayloadAction<Partial<CreatorProduct>>
		) => {
			Object.assign(state.product, payload);
		},
		updateOptions: (
			state,
			{ payload }: PayloadAction<Partial<CreatorOptions>>
		) => {
			Object.assign(state.options, payload);
		},
		resetCreator: (state) => {
			Object.assign(state.product, {
				netPrice: 0,
				grossPrice: 0,
				productComponents: [],
			});

			const selectedComponents = getInitialSelectedComponents(
				state.product.productType!.componentCategories
			);
			const selectedAttributes = getInitialSelectedAttributes(
				state.product.productType!.componentCategories
			);

			Object.assign(state.options, { selectedComponents, selectedAttributes });
		},
		changeSelectedComponent: (
			state,
			{
				payload,
			}: PayloadAction<{
				componentCategoryId: UUID;
				component: Component | null;
			}>
		) => {
			state.options.selectedComponents[payload.componentCategoryId] =
				payload.component;
		},
		changeSelectedAttributes: (
			state,
			{
				payload,
			}: PayloadAction<{
				componentCategoryId: UUID;
				attributeId: UUID;
				attributeValueId: SelectedAttributeValue;
			}>
		) => {
			state.options.selectedAttributes[payload.componentCategoryId][
				payload.attributeId
			] = payload.attributeValueId;
		},
		addToHistory: (
			{ options },
			{ payload }: PayloadAction<CreatorSelectedAttributes>
		) => {
			if (
				options.selectedAttributesHistory.length ===
				APP_CONFIG.HISTORY_MAX_LENGTH
			)
				options.selectedAttributesHistory.shift();

			options.selectedAttributesHistory.push(payload);
		},
		undoAttributesSelection: ({ options }) => {
			const prev = options.selectedAttributesHistory.pop();
			if (!prev) return;

			options.selectedAttributes = prev;
		},
	},
});

export const {
	updateCreator,
	updateProduct,
	updateOptions,
	resetCreator,
	changeSelectedComponent,
	changeSelectedAttributes,
	addToHistory,
	undoAttributesSelection,
} = creatorSlice.actions;

export const selectCreatorState = (state: RootState) => state.creator;
export default creatorSlice.reducer;
