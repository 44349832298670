import { em } from '@mantine/core';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	Z_INDEX: {
		OVERLAY: 50,
		SCROLL_TOP: 50,
		NOTIFICATION: 100,
		DRAWER: 104,
		MODAL: 301,
		POPOVER: 302,
		TOOLTIP: 302,
		SELECT: 302,
		CREATOR_LOADING: 303,
	},
	BREAKPOINTS: {
		XS: em(576),
		SM: em(768),
		MD: em(992),
		LG: em(1200),
		XL: em(1408),
	},
	MOBILE_PREVIEW_SHOW_OFFSET: -50,
	MOBILE_PREVIEW_HIDE_OFFSET: 250,
	HISTORY_MAX_LENGTH: 10,
	DEFAULT_LANGUAGE: 'PL',
	CSS_DVH_CHROME_SUPPORTED: 108,
} as const;

export default APP_CONFIG;
