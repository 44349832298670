import { createRoot } from 'react-dom/client';
import { AppProviders } from '@/providers/AppProviders';
import { lazy } from 'react';
import './sass/global.scss';

const App = lazy(() => import('@/components/App'));

const rootBtn = document.getElementById('creator-root')!;
const root = createRoot(rootBtn);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
