import { Box, MantineLoaderComponent } from '@mantine/core';
import { forwardRef } from 'react';
import styles from './Loader.module.scss';
import classNames from 'classnames';

export const Loader: MantineLoaderComponent = forwardRef(
	({ className, ...others }, ref) => {
		return (
			<Box
				className={classNames(styles.spinner, className)}
				{...others}
				ref={ref}
			>
				<div className={styles.spinnerChase}>
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
				</div>
			</Box>
		);
	}
);
