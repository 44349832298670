import { ProductType } from './ProductTypeApi';
import { QueryKey } from '@/configs/queryKeys';
import { Component } from './ComponentApi';
import { Attachment, UUID } from '@/types/types';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

export enum ProductStatus {
	NOT_ADDED,
	ADDDED_TO_CART,
}

export interface ProductComponent {
	id: UUID;
	product: Product;
	quantity: number;
	netPrice: number;
	grossPrice: number;
	componentData: Component;
}

export interface Product {
	id: UUID;
	netPrice: number;
	grossPrice: number;
	productType: ProductType | null;
	productComponents: ProductComponent[];
	status: ProductStatus;
	options: string;
	inscription?: string;
	updatedAt: string;
	image?: Attachment;
	createdAt: string;
}

class ProductApi extends EntityApi<Product> {
	constructor() {
		super(QueryKey.products);
		this.resetProductComponents = this.resetProductComponents.bind(this);
		this.updateProductComponent = this.updateProductComponent.bind(this);
		this.submit = this.submit.bind(this);
	}

	async updateProductComponent({
		productId,
		componentId,
		oldProductComponentId,
	}: {
		productId: UUID;
		componentId?: UUID;
		oldProductComponentId?: UUID;
	}) {
		if (oldProductComponentId)
			await httpClient.delete(
				`${QueryKey.productComponents}/${oldProductComponentId}`
			);

		if (!componentId)
			return await httpClient.get<Product>(`${this.queryKey}/${productId}`);

		const response = await httpClient.post<Product>(
			QueryKey.productComponents,
			{
				product: { id: productId },
				component: { id: componentId },
			}
		);

		return response.data;
	}

	async resetProductComponents(productId: UUID) {
		await httpClient.delete(`${QueryKey.products}/${productId}/items`);
	}

	async submit(productId: UUID) {
		const response = await httpClient.patch<Product>(
			`${this.queryKey}/${productId}`,
			{
				status: ProductStatus.ADDDED_TO_CART,
			}
		);

		return response.data;
	}
}

export default new ProductApi();
